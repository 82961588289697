import $ from 'jquery';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
const scope = 'screens.ds.grid_sidebar';

$.Control.extend('pane_formCreate', {
  isAvailable: function() {
    var cpObj = this;
    return !_.isEmpty(cpObj._view.visibleColumns) && (cpObj._view.valid || isEdit(cpObj));
  },

  _getCurrentData: function() {
    var d = this._super();
    if (!$.isBlank(d)) {
      return d;
    }

    if (!isEdit(this)) {
      return null;
    }
    var view = this._view.cleanCopy();
    view.flags = view.flags || [];
    if (this._view.isPublic()) {
      view.flags.push('dataPublicAdd');
    }
    return view;
  },

  getTitle: function() {
    return I18n.t('form.title', { scope });
  },

  getSubtitle: function() {
    return I18n.t('form.subtitle', { scope });
  },

  getDisabledSubtitle: function() {
    return !this._view.valid ? I18n.t('base.validation.invalid_view', { scope }) :
      I18n.t('form.validation.no_columns', { scope });
  },

  _getSections: function() {
    return [{
      title: I18n.t('form.information.title', { scope }),
      fields: [{
        type: 'text',
        text: I18n.t('form.information.name', { scope }),
        name: 'name',
        required: true,
        prompt: I18n.t('form.information.name_prompt', { scope })
      }, {
        type: 'text',
        text: I18n.t('form.information.success', { scope }),
        name: 'displayFormat.successRedirect',
        extraClass: 'url',
        prompt: I18n.t('form.information.success_prompt', { scope })
      }, {
        type: 'checkbox',
        text: I18n.t('form.information.public', { scope }),
        name: 'flags.dataPublicAdd',
        defaultValue: true
      }]
    }];
  },

  _getFinishButtons: function() {
    return [isEdit(this) ? $.controlPane.buttons.update :
      $.controlPane.buttons.create, $.controlPane.buttons.cancel
    ];
  },

  _finish: function(data, value, finalCallback) {
    var cpObj = this;
    if (!cpObj._super.apply(this, arguments)) {
      return;
    }

    var view = $.extend(true, {
        displayFormat: null,
        displayType: 'form',
        metadata: {}
      },
      cpObj._getFormValues(), {
        metadata: cpObj._view.metadata
      });
    view.metadata.renderTypeConfig.visible = {
      form: true
    };

    // audience changes are not allowed on working copies.
    // so move the "Public?" request into the sessionStorage so that we
    // may make the form public after publication.
    var formShouldBePublic = isPublicForm(view);
    if (formShouldBePublic) {
      view.flags = view.flags.filter(function(f) { return f !== 'dataPublicAdd'; });
    }
    var doEdit = isEdit(cpObj);
    view.metadata.availableDisplayTypes = ['form'];
    cpObj._view.update(view);

    if (!doEdit) {
      var newView;
      var finish = _.after(2, function() {
        cpObj._finishProcessing();
        if (_.isFunction(finalCallback)) {
          finalCallback();
        }
        newView.redirectTo();
      });

      cpObj._view.getParentDataset(function(parDS) {
        if (!parDS.publicationAppendEnabled) {
          parDS.update({
            publicationAppendEnabled: true
          });
          parDS.save(finish,
            function(xhr) {
              cpObj._genericErrorHandler(xhr);
            }, {
              publicationAppendEnabled: true
            });
        } else {
          finish();
        }
      });

      cpObj._view.saveNew(function(nv) {
        newView = nv;
        if (formShouldBePublic) {
          window.sessionStorage.setItem('dataPublicAdd', newView.id);
        }
        finish();
      }, function(xhr) {
        cpObj._genericErrorHandler(xhr);
      });
    } else {
      var updateView = function() {
        cpObj._view.save(function(resultView) {
          cpObj._finishProcessing();

          if (!$.isBlank(cpObj.settings.renderTypeManager)) {
            var $form = cpObj.settings.renderTypeManager.$domForType('form').find('form.formView');
            var newRedirect = resultView.displayFormat.successRedirect;
            if ($.isBlank(newRedirect)) {
              newRedirect = $form.attr('data-defaultSuccessRedirect');
            }
            var act = $form.attr('action');
            act = $.urlParam(act, 'successRedirect', escape(newRedirect));
            $form.attr('action', act);
          }

          // Global replace
          $('.currentViewName').text(resultView.name);

          cpObj._showMessage('Your form has been updated');
          cpObj._hide();
          if (_.isFunction(finalCallback)) {
            finalCallback();
          }
        });
      };
      updateView();
    }
  }
}, {
  name: 'formCreate'
}, 'controlPane');


var isEdit = function(cpObj) {
  return cpObj._view.type == 'form';
};

var isPublicForm = function(view) {
  return _.includes(view.flags || [], 'dataPublicAdd') ||
    _.some(view.grants || [], function(g) {
      return g.type == 'contributor' && _.includes(g.flags || [], 'public');
    });
};

const formsAvailable = FeatureFlags.value('enable_form_creation');
if (
  formsAvailable &&
  ($.isBlank(blist.sidebarHidden.embed) || !blist.sidebarHidden.embed.formCreate)
) {
  $.gridSidebar.registerConfig('embed.formCreate', 'pane_formCreate', 5, 'form');
}
