(function($) {

  $.Control.extend(
    'pane_sortRollUpDisabled',
    {
      isAvailable: function() {
        return true;
      },

      getTitle: function() {
        return $.t('screens.ds.grid_sidebar.sort_roll_up_disabled.title');
      },

      getSubtitle: function() {
        return $.t('screens.ds.grid_sidebar.sort_roll_up_disabled.subtitle');
      },

      getDisabledSubtitle: function() {
        return '';
      },

      _getSections: function() {
        return [];
      },

      _getFinishButtons: function() {
        return [];
      },

      // Arguments provided to this method: 'data', 'value', 'finalCallback'
      _finish: function() {}
    },
    { name: 'sortRollUpDisabled' },
    'controlPane'
  );

  $(document).ready(function() {
    var showPane = _.get(window, 'socrata.gridView.sidebarPaneVisibility.filter.sortRollUpDisabled', false);

    if (showPane) {
      $.gridSidebar.registerConfig('filter.sortRollUpDisabled', 'pane_sortRollUpDisabled', 3);
    }
  });
})(jQuery);
