/**
 * Updated July 2019 to support jquery 3:
 * - Breaking change: .andSelf() removed, use .addBack(): https://jquery.com/upgrade-guide/3.0/#traversing
 */

import $ from 'jquery';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import getLocale from 'common/js_utils/getLocale';

const typeOrder = ['column', 'stackedcolumn', 'bar', 'stackedbar', 'pie', 'donut', 'line', 'area', 'timeline', 'bubble', 'treemap'];
let classicChartRequested = false;
const classicChartsAvailable =
  !FeatureFlags.value( 'force_use_of_modifying_lens_id_in_all_derived_views') &&
  FeatureFlags.value('enable_classic_chart_creation');
const enableStandaloneVizCreation = FeatureFlags.value('enable_standalone_visualization_creation');
const scope = 'screens.ds.grid_sidebar';

$.Control.extend('pane_chart_create', {
    _init: function() {
      const cpObj = this;
      cpObj._super.apply(cpObj, arguments);
      cpObj._view.bind('clear_temporary', function() {
        cpObj.reset();
      }, cpObj);

      cpObj.$dom().on('click', '.showConditionalFormatting', function(e) {
        e.preventDefault();
        if ($.subKeyDefined(blist, 'datasetPage.sidebar')) {
          blist.datasetPage.sidebar.show('filter.conditionalFormatting');
        }
      });

      cpObj.$dom().on('click', '.clearConditionalFormatting', function(e) {
        e.preventDefault();
        var metadata = $.extend(true, {}, cpObj._view.metadata);
        delete metadata.conditionalFormatting;
        cpObj._view.update({
          metadata: metadata
        });
      });
    },

    render: function() {
      const cpObj = this;
      cpObj._super(null, null, function(completeRerender) {
        if (completeRerender) {
          //setup DOM
          cpObj.$dom().find('.chartTypeSelection .radioLine').each(function(index, node) {
            $(node).addClass(typeOrder[index]);
          });

          //Push custom icons into sidebar dom
          cpObj.$dom().find('.chartTypeSelection .formHeader').
            append($.tag({
              tagName: 'div',
              'class': ['currentSelection'],
              contents: [{
                tagName: 'span',
                'class': ['selectionName']
              }, {
                tagName: 'span',
                'class': ['selectionIcon']
              }]
            }));

          //Insert icon elements where needed
          cpObj.$dom().find('.line.hasIcon').
            after($.tag({
              tagName: 'div',
              'class': ['lineIcon']
            }));

          cpObj._renderVisualizationCanvasButton();

          //Repeater add buttons change icon of section
          cpObj.$dom().find('.hasIcon.repeater .addValue').
            on(
              'hover',
              function() {
                $(this).parent().siblings().filter('.lineIcon').addClass('hover');
              },
              function() {
                $(this).parent().siblings().filter('.lineIcon').removeClass('hover');
              }
            );

          if (!cpObj._view.metadata.conditionalFormatting) {
            cpObj.$dom().find('.conditionalFormattingWarning').hide();
          }
          //hide *Required text, people get it
          cpObj.$dom().find('.mainError+.required').hide();



          //setup eventing

          //Add flyout to unavailable chart types telling which columns are required
          cpObj.$dom().find('.unavailable').each(function() {
            var $t = $(this);
            var tip = $.htmlUnescape($t.find('label input').attr('title'));
            $t.socrataTip({
              content: tip
            });
          });

          //takes in classname <'radioLine' ('unavailable')? type>
          var setHeader = function(type) {
            var current = type.split(' ')[1];
            if (current != 'unavailable') {
              cpObj.$dom().find('.formSection.chartTypeSelection, .paneContent').
                removeClass(function(i, oldClasses) {
                  var matches = oldClasses.match(/\S*-selected/);
                  return ($.isBlank(matches) ? '' : matches.join(' '));
                }).
                addClass(type + '-selected').
                //Set the text to current icon type
                find('.currentSelection .selectionName').
                text(Dataset.chart.types[current].text);
            }
          };

          if (blist.dataset.displayFormat.chartType) {
            setHeader('radioLine ' + blist.dataset.displayFormat.chartType);
          }

          //Bind section classing to chart-type selection
          cpObj.$dom().find('.chartTypeSelection .radioLine').on('click', function(e) {
            setHeader($(e.currentTarget).attr('class'));
          });

          //Clicking minus button repeater triggers rerender
          cpObj.$dom().find('.repeater').
            on('click', '.removeLink', function(e) {
              cpObj._changeHandler($(e.currentTarget));
            });

          //Section hiding animations
          cpObj.$dom().find('.formSection.selectable .sectionSelect').on('click', function(e) {
            var $sect = $(e.currentTarget).closest('.formSection');

            //shown/hidden by base-pane eventing so needs to be shown and then reset to for animation to run
            if ($sect.hasClass('collapsed')) {
              $sect.find('.sectionContent').show().slideUp({
                duration: 100,
                easing: 'linear'
              });
            } else {
              $sect.find('.sectionContent').hide().slideDown({
                duration: 100,
                easing: 'linear'
              });
            }
          });

        }
      });
    },

    //Append area can be before, after ...
    _getSections: function() {
      const cpObj = this;

      var options = {
        view: cpObj._view,
        isEdit: isEdit(cpObj) && !cpObj._view.isGrouped(),
        useOnlyIf: true
      };

      var result = blist.configs.chart.configChartSelector(options);

      var type = cpObj._view.displayFormat.chartType;
      if (type) {
        result = result.concat(blist.configs.chart.newConfigForType(type, {
          view: cpObj._view,
          isEdit: isEdit(cpObj) && !cpObj._view.isGrouped(),
          useOnlyIf: true
        }));
      }

      return result;
    },

    getTitle: function() {
      if (!FeatureFlags.value('enable_classic_map_creation')) {
        // This is the preferred title going forward. The alternatives below are not ideal:
        // "chart": Customers looking to make a map understandably don't click on a thing that claims
        //          to make charts.
        // "visualization": Customers don't necessarily know that maps are also visualizations.
        // However, to prevent the additional confusion of a tab called "Map" and another tab called
        // "Chart or Map", we only take this preferred branch if classic maps are disabled on the
        // domain. This is expected to be global very soon, which will allow us to remove all the
        // conditionals in this method.
        return I18n.t('chart.chart_or_map', { scope });
      } else if (classicChartsAvailable) {
        return I18n.t('chart.title', { scope });
      } else {
        // Only the "Create New Visualization" button will be visible. Change the name of the header to be sane.
        return I18n.t('chart.new_viz_title', { scope });
      }
    },

    _getCurrentData: function() {
      return this._super() || this._view;
    },

    isAvailable: function() {
      return (this._view.valid || isEdit(this)) &&
        (_.includes(this._view.metadata.availableDisplayTypes, 'chart') ||
          this._view.shouldShowViewCreationOptions());
    },

    getDisabledSubtitle: function() {
      return !this._view.valid && !isEdit(this) ?
        I18n.t('base.validation.invalid_view', { scope }) : I18n.t('chart.validation.viz_limit', { scope });
    },

    validateForm: function() {
      var valid = this._super();
      this._updateErrorVisibility();
      return valid;
    },

    _renderVisualizationCanvasButton: function() {
      const cpObj = this;
      const ds = blist.dataset;

      // EN-27128 - Issues saving edits to classic visualizations
      //
      // When investigating this bug we found that clicking the 'continue
      // to classic chart creation' link would fix it, but the only thing
      // that clicking that link would do is remove the container for the
      // visualization canvas button and then unhide a couple of DOM
      // elements.
      //
      // We also found that when this bug is triggered, no network request
      // is made when the chart configuration changes in a way that should
      // trigger a network request.
      //
      // From these data we deduce that something about how the visualization
      // pane interacts with the dataset model depends on DOM nodes either
      // a) being the first children of a parent element or b) not being
      // hidden, either with the 'hide' class or with 'display: none'.
      //
      // We can prevent this bug from being triggered by not showing the
      // visualization canvas button if the user is viewing something that
      // is not a tabular dataset (for example, a previously-configured
      // chart), because showing the visualization canvas button has a
      // side effect of causing the chart configuration controls to be
      // visible and able to be interacted with, but in a way that does
      // not actually cause valid changes to the chart configuration when
      // the user changes a chart control.
      if (ds.viewType !== 'tabular' || ds.displayType !== 'table') {
        return;
      }

      ds.getNewBackendMetadata().
        then(function() {
          // This is unnecessary if there are existing vizcan buttons
          if (cpObj.$dom().find('.visualization-canvas-bootstrap').length) {
            return;
          }

          const locale = getLocale();
          const localePrefix = (locale === blist.defaultLocale) ? '' : `/${locale}`;

          // Use the derived view ID for a derived view, the NBE ID otherwise.
          const isDerivedView = ds.id !== ds._obeId && ds.id !== ds._nbeId;
          const id = isDerivedView ? ds.id : ds._nbeId;
          const bootstrapUrl = `${localePrefix}/d/${id}/visualization`;

          if (ds.publicationStage === 'unpublished') {
            return;
          }

          const label = cpObj.$dom().find('.chartTypeSelection > label.formHeader');

          const newVizButton = $.tag({
            tagName: 'a',
            href: bootstrapUrl,
            'class': 'visualization-canvas-button',
            contents: I18n.t('visualization_canvas.button', { scope }),
            target: '_blank'
          });

          if (enableStandaloneVizCreation) {
            label.before(
              $('<div>').
                addClass('visualization-canvas-bootstrap').
                append(newVizButton)
            );
          }

          // NOTE: The whole sidebar section gets re-rendered for pointless
          // reasons, so we use a control variable held in outermost scope to
          // ensure that we only show this link once.
          if (classicChartRequested) {
            return;
          }

          if (classicChartsAvailable) {
            newVizButton.after(
              $.tag({
                tagName: 'a',
                href: '#continue-to-classic',
                'class': 'visualization-canvas-ignore-link',
                contents: I18n.t('visualization_canvas.link_to_classic', { scope })
              }).on('click', function() {
                const link = $(this);
                link.closest('.formSection').find('.hide').removeClass('hide').css({display: ''});
                link.remove();
                classicChartRequested = true;
                return false;
              })
            );
          }

          // Have to add redundant `display: none` because Grid View Refresh 2017 uses
          // `!important` to override styles inherited from elsewhere, and refactoring
          // the CSS to cause less unintentional inheritance is a massive task that we
          // cannot justify at the moment.
          label.nextAll().addBack().addClass('hide').attr({style: 'display: none !important'});
        }).
        fail(_.noop); // No NBE available = no vizcan button.
    },

    _updateErrorVisibility: function(valCols) {
      //If creating from a dataset don't spit warning messages immediately.
      valCols = valCols || this._view.displayFormat.valueColumns;
      var hideInlineErrors = _.isEmpty(valCols) || _.some(valCols, _.isEmpty);
      this.$dom().toggleClass('inlineErrorsHidden', hideInlineErrors || false);
    },

    _changeHandler: function($input) {
      const cpObj = this;
      _.defer(function() {
        var initSidebarScroll = cpObj.$dom().closest('.panes').scrollTop();
        var originalChartType = $.subKeyDefined(cpObj._view, 'displayFormat.chartType') ? cpObj._view.displayFormat.chartType : undefined;
        var isBrandNewChart = _.isEmpty(originalChartType);
        var newValues = cpObj._getFormValues();

        ///VALIDATE///

        if ($input.data('origname') == 'displayFormat.chartType') {
          var newChartType = newValues.displayFormat.chartType;
          var isSameChart = !isBrandNewChart && originalChartType == newChartType;
          if (cpObj.validateForm() || !isSameChart) {
            // Need to run the config through chart translation in case
            // things need to change/update
            if (_.isFunction(Dataset.chart.types[newChartType].translateFormat)) {
              newValues.displayFormat =
                Dataset.chart.types[newChartType].translateFormat(cpObj._view,
                  newValues.displayFormat);
            }

            // For a new chart type selection, push the change through even if we don't validate.
            // The reset will take care of sanitization itself.
            cpObj._view.update(
              $.extend(true, {}, newValues, {
                metadata: cpObj._view.metadata
              })
            );
            cpObj._updateErrorVisibility();
          }
          cpObj.reset();
          newValues = cpObj._getFormValues();
        }

        if (!cpObj.validateForm()) {
          cpObj._updateErrorVisibility(newValues.displayFormat.valueColumns);
          cpObj.$dom().closest('.panes').scrollTop(initSidebarScroll);
          return;
        }

        //Clean-up sparse inputs in value column repeater so colors sync and merge correctly.
        cpObj.$dom().find("[class*='ValueSelection'] .line").each(function(i, el) {
          var $line = $(el);
          if ($.isBlank($line.find('.columnSelectControl').val())) {
            $line.remove();
          }
        });

        //TODO: clean this up a bit, custom content with linkedFields + onlyIfs behave strangely
        //Show the correct color selection method with dsg enabled
        var $dsgColors = cpObj.$dom().find('.colorArray');
        var $colColors = cpObj.$dom().find('.colors');
        //If manually hidden through onlyIf
        if ($dsgColors.css('display') == 'none' && $colColors) {
          $colColors.show();
        } else {
          $colColors.hide();
        }

        var view = $.extend(true, {
            metadata: {
              renderTypeConfig: {
                visible: {
                  chart: true
                }
              }
            }
          },
          newValues, {
            metadata: cpObj._view.metadata
          });

        var addColumn = function(colId) {
          var col = cpObj._view.columnForIdentifier(colId);
          if (_.some(col.renderType.aggregates, function(a) {
              return a.value == 'sum';
            }))
            col.format.aggregate = 'sum';
        };

        _.each(view.displayFormat.fixedColumns || [], addColumn);

        // Conditionally apply a default pie/donut sort.
        var isPieStyleChart = _.includes(['pie', 'donut'], view.displayFormat.chartType);
        var isSameChartType = !isBrandNewChart && originalChartType == view.displayFormat.chartType;
        if ((isBrandNewChart || isSameChartType) && isPieStyleChart &&
          !$.subKeyDefined(cpObj, '_view.metadata.jsonQuery.order')) {
          view.metadata = $.extend(true, view.metadata, cpObj._view.metadata);
          view.metadata.jsonQuery.order = cpObj._getPieDefaultOrderBy(view.displayFormat.valueColumns);
        }

        if (((view.displayFormat.chartType == 'bar') || (view.displayFormat.chartType == 'column')) &&
          (view.displayFormat.stacking == true)) {
          view.displayFormat.chartType = 'stacked' + view.displayFormat.chartType;
        }
        cpObj._view.update(view);
        cpObj._updateErrorVisibility();



        //TEST WITH FILTERS

        if (isEdit(cpObj)) {
          // We need to show all columns when editing a view so that
          // any filters/facets work properly
          var colIds = _.map(cpObj._view.realColumns, 'id');
          if (colIds.length > 0) {
            cpObj._view.setVisibleColumns(colIds, null, true);
          }
        }

        cpObj.$dom().closest('.panes').scrollTop(initSidebarScroll);

        cpObj._finishProcessing();
      });
    },

    // NOTE: Keep this in sync with the one in d3.impl.pie.js!
    _getPieDefaultOrderBy: function(valueColumns) {
      const cpObj = this;
      return _.map(valueColumns, function(col) {
        return {
          ascending: false,
          columnFieldName: cpObj._view.columnForIdentifier(col.fieldName || col.tableColumnId).fieldName
        };
      });
    }

  }, {
    name: 'chart_create'
  },
  'controlPane');

var isEdit = function(cpObj) {
  return _.includes(cpObj._view.metadata.availableDisplayTypes, 'chart');
};

if (!blist.sidebarHidden.visualize.chartCreate) {
  $.gridSidebar.registerConfig('visualize.chart_create', 'pane_chart_create', 0);
}
