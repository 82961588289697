(function($) {
  $.Control.extend('pane_about', {
    getTitle: function() {
      return $.t('controls.common.sidebar.tabs.about');
    },

    _getSections: function() {
      return [{
        customContent: {
          template: 'aboutDataset',
          directive: {},
          data: {},
          callback: function($sect) {

            $sect.find('.starsControl').each(function() {
              blist.datasetControls.datasetRating($(this), $sect, true);
              // HACK: basePane validates the parent form; but the validator
              // crashes because it can't find a validate object on the star form
              // Bad nested forms!
              $(this).validate();
            });

            if (!blist.currentUserId) {
              $sect.find('.yourRating').socrataTip($.t('screens.about.tooltips.rating_disabled'));
            }

            $sect.find('.expander').on('click', function(event) {
              event.preventDefault();
              var $this = $(this);

              $this.toggleClass('expanded').
                toggleClass('collapsed').
                siblings('.sectionContent').
                slideToggle($this.hasClass('expanded'));
            });

            blist.datasetControls.datasetContact($sect);
          }
        }
      }];
    }
  }, {
    name: 'about'
  }, 'controlPane');

  if (!blist.sidebarHidden.about) {
    $.gridSidebar.registerConfig('about', 'pane_about');
  }

})(jQuery);
