 /**
* Updated July 2019 to support jquery 3.4.1
* - bind/unbind is deprecated: https://jquery.com/upgrade-guide/3.0/#deprecated-bind-and-delegate
* - select and focus shorthand is deprecated: https://github.com/jquery/jquery-migrate/blob/master/warnings.md#jqmigrate-jqueryfnclick-event-shorthand-is-deprecated
*/

(function($) {
  $.Control.extend('pane_api', {
    getTitle: function() {
      return $.t('screens.ds.grid_sidebar.api.title');
    },

    getSubtitle: function() {
      return $.t('screens.ds.grid_sidebar.api.subtitle', {
        view_type: this._view.displayName
      });
    },

    render: function() {
      var cpObj = this;
      cpObj._super(null, null, function(completeRerender) {
        if (completeRerender) {
          cpObj.$dom().find('input').on('click', function() {
            var $this = $(this);
            $this.trigger('focus');
            $this.trigger('select');
          });
          ZeroClipboard.config({
            swfPath: '/ZeroClipboard.swf'
          });
          new ZeroClipboard(cpObj.$dom().find('button')[0]);
        }
      });
    },

    _getSections: function() {
      return [{
        customContent: {
          template: 'apiContentWrapper',
          directive: {
            'p@class+': 'pClass'
          },
          data: {
            pClass: 'sectionContent'
          }
        }
      }];
    }
  }, {
    name: 'api'
  }, 'controlPane');

  if ($.isBlank(blist.sidebarHidden.exportSection) || !blist.sidebarHidden.exportSection.api) {
    $.gridSidebar.registerConfig('export.api', 'pane_api', 10);
  }

})(jQuery);
