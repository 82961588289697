(function($) {

  $.Control.extend(
    'pane_queryEditor',
    {
      isAvailable: function() {
        return true;
      },

      getTitle: function() {
        return $.t('screens.ds.grid_sidebar.query_editor.title');
      },

      getSubtitle: function() {
        return '';
      },

      getDisabledSubtitle: function() {
        return '';
      },

      _getSections: function() {
        return [];
      },

      _getFinishButtons: function() {
        return [];
      },

      // Arguments provided to this method: 'data', 'value', 'finalCallback'
      _finish: function() {}
    },
    { name: 'queryEditor' },
    'controlPane'
  );

  $(document).ready(function() {
    var showPane = _.get(window, 'socrata.gridView.sidebarPaneVisibility.filter.soqlQueryEditor', false);

    if (showPane) {
      $.gridSidebar.registerConfig('filter.queryEditor', 'pane_queryEditor', 1, 'filter');
    }
  });
})(jQuery);
