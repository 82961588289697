(function($) {
  $.Control.extend('pane_downloadDataset', {
    getTitle: function() {
      return $.t('screens.ds.grid_sidebar.download.title');
    },

    getSubtitle: function() {
      return $.t('screens.ds.grid_sidebar.download.subtitle');
    },

    isAvailable: function() {
      return this._view.valid;
    },

    getDisabledSubtitle: function() {
      return $.t('screens.ds.grid_sidebar.base.validation.invalid_view');
    },

    _getSections: function() {
      const cObj = this;
      const _view = this._view;
      const isLayered = _view.newBackend && _view.isLayered();

      /**
       * copied from ExportFlannel.jsx in datasetLandingPage
       *
       * Used below to filter out the 'CSV for Excel' options from the list of links if
       * the hide_csv_for_excel_download feature flag is set to true.
       *
       * The code here is expecting a hard-coded string that is not currently translated. It
       * comes from NORMAL_DATASET_DOWNLOAD_TYPES in the common/views/constants.js. If that is ever changed
       * this code will need to be updated.
       */
      function csvForExcelOrTrue(value) {
        return !(!!blist.feature_flags.hide_csv_for_excel_download && value.match(/^CSV for Excel/));
      }

      const downloadSets = this._view.getDownloadSets();
      const downloadTypes = downloadSets.map(function(set) {
        return $.templates.downloadsTable.downloadSets[set];
      }).flat().filter(csvForExcelOrTrue);

      const layerDownloadSets = isLayered ? ['tabular', 'geospatial'] : ['tabular'];
      const layerDownloadTypes = layerDownloadSets.map(function(set) {
        return $.templates.downloadsTable.downloadSets[set];
      }).flat().filter(csvForExcelOrTrue);

      const isGeo = GeoHelpers.isGeoDataset(this._view);
      const directiveType = isGeo ? 'possibly_layered' : 'never_layered';

      return [{
        customContent: {
          template: 'downloadsSectionContent',
          directive: $.templates.downloadsTable.directive[directiveType],
          data: {
            downloadTypes: downloadTypes,
            layerDownloadTypes: layerDownloadTypes,
            view: this._view
          },
          callback: function($sect) {
            if (!isGeo) {
              $sect.find('.downloadsList .item a').downloadToFormCatcher(_view,
                cObj.$dom());
            } else {
              _view.getChildOptionsForType('table', function(views) {

                var hookupLinks = function(uid) {
                  $sect.find('.layerDownloadsContent .item a').each(function() {
                    var $link = $(this);
                    var childView = _.find(views, function(view) {
                      return view.id == uid;
                    });
                    $link.attr('href', childView.downloadUrl($link.data('type')));
                  });
                };

                hookupLinks(views[0].id);

                if (views.length > 1) {
                  $sect.find('.layerTableDownloads').
                    find('.layerChooser').
                    append(_.map(views, function(view) {
                      return $.tag({
                        tagName: 'option',
                        contents: view.name,
                        'data-uid': view.id
                      }, true);
                    }).join('')).
                    change(function() {
                      hookupLinks($(this).find('option:selected').data('uid'));
                    }).
                    end().addClass('hasChoices');
                }
              });
            }
            $.templates.downloadsTable.postRender($sect);
          }
        }
      }];
    }
  }, {
    name: 'downloadDataset'
  }, 'controlPane');

  if ($.isBlank(blist.sidebarHidden.exportSection) || !blist.sidebarHidden.exportSection.download) {
    $.gridSidebar.registerConfig('export.downloadDataset', 'pane_downloadDataset', 1);
  }

})(jQuery);
